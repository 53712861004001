

body,
html {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-display: swap;
}


p, .p {
  font-size: 16px;
  line-height: 1.5;
  &.small {
    font-size: 14px;
  }
  &.xsmall {
    font-size: 12px;
    line-height: 1.25;
  }
}

small, .small {
  font-size: 80%;

  &-lineheight {
    line-height: 100%;
  }
}

@media(max-width: 768px) {
  .mobile-xsmall {
    font-size: 12px !important;
  }
}

h5, .h5 {
  font-size: 1.1rem;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
.nav-item {
  // letter-spacing: 0.05em;
  color: var(--ion-color-dark);
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.3px;
  small{
    font-weight: 100;
  }
}

.text-hide {
  opacity: 0;
  font-size: 1px;
  line-height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, .h5{
  font-weight: 600;
}

h1, .h1 {
  font-size: 3rem;
  font-weight: 900;
  max-width: 90%;
  @media (max-width: 768px){
    font-size: 2rem;
  }
}

a:hover {
  color: var(--ion-color-primary-tint);
}

// .black-box, h1, .h1 {
//   background-color: var(--ion-color-dark);
//   color: var(--ion-color-dark-contrast);
//   display: inline-block;
//   padding: 0.35em 0.6em;
//   font-weight: bold;
// }

.black-box--precise {
  background-color: transparent;
  line-height: 1.8em;
  & > span {
    background-color: var(--ion-color-dark);
    box-shadow: 0.8em 0 0 var(--ion-color-dark), -0.8em 0 0 var(--ion-color-dark);
    padding: 0.4em 0;
  }
}

p, h1, h2, h3, h4, h5, h6{
  &:hover{
    text-decoration: none !important;
  }
}

h2, .h2 {
  @media (max-width: 768px){
    font-size: 1.7rem;
  }
}

.text-medium-tint {
  color: var(--ion-color-medium-tint) !important;
}

a {
  color: inherit;
  &.text-medium{
    &:hover{
      color: var(--ion-color-primary) !important;
    }
  }
  &.text-primary{
    &:hover{
      color: var(--ion-color-primary) !important;
      text-decoration: underline;
    }
  }
}

.h1--small {
  font-size: 1.4rem !important;
  font-weight: 600;
}

.text-shadow {
  text-shadow: 1px 1px 3px rgb(45, 55, 67);
}

.text-thin {
  font-weight: 100;
}

.text-uppercase {
  text-transform: uppercase;
}

*:focus,
*:active {
  outline: none !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul{
  padding-left: 1em;
}

.large {
  font-size: 1.5em;
}

.prewrap {
  white-space:pre-wrap;
}


.logo-name {
  // letter-spacing: 0.2em;
  font-weight: 900;
  font-size: 22px;
  @media (max-width: 992px) {
    font-size: 16px;
  }
}


.font-weight-bold {
  font-weight: 900;
}



.branding-text {
  letter-spacing: 3px;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  strong {
    font-weight: 900 !important;
  }
}

.elipsis {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: inline-block !important;
}