

// Global Styles
.flex-center {
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}


.container--narrow {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container--large {
  max-width: 1350px;
}


.image-banner--curved.image-banner-loading {
  width: 100%;
  min-height: 80vh;
  overflow: hidden;
  position: relative;
  background: #ccc;

}

ngx-spinner .overlay {
  background: var(--ion-color-dark-contrast) !important;
  .la-ball-scale-multiple {
    width: 120px;
    height: 120px;
    color: var(--ion-color-dark) !important;
    opacity: 1;
  }
}

.image-banner {
  width: 100%;
  height: auto;
  min-height: 70vh;
  overflow: hidden;
  position: relative;
  &--curved {
    min-height: 80vh;
  }
  @media (max-width: 768px) {
    min-height: 70vh;
  }
  .cover-img {
    position: absolute;
  }
  h1{
    text-align: center;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    &--transparent{
      background: rgba(45, 55, 67, 0.1);
    }
    .logo {
      width: 100px;
      height: 100px;
      border: 1px solid var(--ion-color-dark);
      -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      img-fade {
        border: 2px solid #ffffff;
        width: 100%;
        height: 100%;
        object-fit: cover;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &--box{
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      border-radius: 10px;
      padding: 1em 2em;
      transform: translate(-50%, -50%);
      background: rgba(255,255,255,0.6);
      box-shadow: rgba(25, 28, 31, 0.06) 0px 3px 6px, rgba(25, 28, 31, 0.06) 0px 1px 2px, rgba(25, 28, 31, 0.04) 0px -1px 0px;
      border: 1px solid var(--ion-color-light);
    }
  }
  &.no-image{
    min-height: 0;
    .overlay{
      position: relative;
      width: 100%;
      padding: 10vh 0 0;
      height: auto;
    }
  }

  // add inset shadow over content below
  &--shadow {
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      pointer-events: none;
      top: 0;
      width: 100%;
      box-shadow: inset 0 -25px 21px -10px rgba(0,0,0,0.9);
      -webkit-box-shadow: inset 0 -25px 21px -10px rgba(0,0,0,0.9);
    }
  }
}

.img-placeholder {
  background-repeat: no-repeat;
  background-size: 80%;
  background-color: var(--ion-color-light);
  background-position: center;
  position: relative;
}

.skeleton-bg {
  position: relative;
  &::after {
    content: "";
    height: 150%;
    width: 150%;
    display: block;
    position: absolute;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      var(--ion-color-light);
    background-repeat: repeat-y;
    background-size: 75px 500px;
    background-position: 0 0;
    animation: shine 2s infinite;
  }
  * {
    z-index: 1;
  }
}
@keyframes shine {
  to {
    background-position: 100% 0, 0 0;
  }
}

.skeleton {

  &-banner {

    .loader {
      width: 100% !important;
      height: 65vh !important;

      @media(max-width: 768px) {
        height: 70vh !important;
      }
    }
  }

  &-logo {
    transform: translateY(-50%);
    display: block;
    margin: auto;
    margin-bottom: -8rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
    .loader {
      overflow: hidden !important;
      width: 11rem !important;
      height: 11rem !important;
      border-radius: 100% !important;
      border: 6px solid var(--ion-color-light) !important;
      position: relative !important;
      flex-shrink: 0 !important;
    }
  }
}

section {
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media (max-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.cover-img {
  width: 100%;
  min-height: 100%;
  position: relative;
  background-image: url(/assets/images/placeholder.jpg);
  transition: transform 0.05s ease;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  img{
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: inherit;
    object-position: inherit;
    border-radius: inherit;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  @media (max-width: 768px){
    width: auto;
    min-width: 100%;
  }
}

.banner-bg{
  position: absolute;
  min-height: 65vh;
  // min-height: calc(80vh + 5em);
  width: 100%;
  background-size: cover;
  transition: transform 0s;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  @media (max-width: 768px){
    min-height: 70vh;
    min-height: calc(70vh + 5em);
    background-attachment: unset;
  }
  &--blurred{
    filter: blur(10px);
    background-color: var(--ion-color-light);
  }
  &--phone {
    background-size: auto 90%;
    background-color: #161616;
    background-position: right bottom;
    background-position: calc(50% + 500px) bottom;
    @media (max-width: 1400px){
      background-position: right bottom;
    }
  }

  &--curved {
    min-height: 80vh;
    min-height: calc(80vh + 5em);
  }
}

.page-content, .showcase-container {
  background-color: #ffffff;

  &--noborderradius {
    border-radius: 0 !important;
  }
}
.image-banner--curved + .page-content,
  .skeleton-banner--curved + .page-content {

  &, & > div:first-child, & > section:first-child {

    margin-top: -5em;
    border-top-left-radius: 5em;
    border-top-right-radius: 5em;
    position: relative;
    padding: 0;

    @media (max-width: 768px) {
      margin-top: -2em;
      border-top-left-radius: 2em;
      border-top-right-radius: 2em;
    }

  }

}

.bg-light .showcase-container {
  background-color: var(--ion-color-light);
}

.text-primary {
  color: var(--ion-color-primary) !important;
}

.text-medium {
  color: var(--ion-color-medium) !important;
}

.text-dark-tint {
  color: var(--ion-color-dark-tint);
}

.text-success{
  color: var(--ion-color-success);
}
.text-danger{
  color: var(--ion-color-danger);
}

.pattern-bg {
  background-image: url(/assets/images/pattern-bg.jpg);
  background-size: cover;
  background-position: center;
  &--fade-bottom{
    position: relative;
    &:after{
      content: "";
      background: linear-gradient(to top, white, transparent);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 20px;
      left: 0;
    }
  }
}

.video-container {
  height: 500px;
  width: 100%;
  position: relative;
}

.info-content {
  overflow-wrap: break-word !important;
  font-size: 1.15rem !important;
  font-weight: 400 !important;
  line-height: 1.66667em !important;
  margin: 0px !important;
}

.side-scroller{
  width: 100%;
  overflow-x: auto;
}
.side-scroller::-webkit-scrollbar {
  width: 6px;
}
.side-scroller {
  scrollbar-width: thin;
  scrollbar-color: var(--ion-color-light-shade);
}
.side-scroller::-webkit-scrollbar-track {
  background: var(--ion-color-light);
}
.side-scroller::-webkit-scrollbar-thumb {
  background-color: var(--ion-color-light-shade);
  border-radius: 6px;
  border: 3px solid var(--ion-color-light);
}

.gallery{
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  &__title{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.3em 2em;
    background-color: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 1;
    font-size: 1.75em;
    border-bottom-left-radius: 0.25em;
    text-align: center;
    border-bottom-right-radius: 0.25rem;
    @media (max-width: 768px) {
      width: 100%;
      padding: 0.3em;
      font-size: 1.5rem;
      left: 0;
      transform: none;
    }
  }
}

.gallery-img {
  flex-basis: 100%;
  min-width: 40vw;
  height: 50vh;
  overflow: hidden;
  border-bottom: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
  @media (max-width: 768px) {
    height: 40vh;
  }
  &:last-child{
    border-right: none;
  }
  & > img, & > img-fade, & > video {
    border-radius: inherit;
    height: auto;
    max-height: none;
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    position: absolute;
    left: 50%;
    display: block;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  & > img, & > video {
    max-height: none !important;
    max-width: none !important;
    object-fit: cover !important;
  }
  // &:hover{
  //   & > img, & > video{
  //     transform: translate(-50%, -50%) scale(1.1) !important;
  //   }
  // }
  @media (max-width: 768px) {
    min-width: 75vw;
  }
  &--small {
    flex-basis: 25%;
    @media (max-width: 768px) {
      flex-basis: 50%;
    }
  }
}

.preview-img {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  .overlay {
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    transition: opacity 0.4s 0.1s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  img, video {
    transition: transform 0.5s ease-out;
  }
  &:hover {
    .overlay {
      // opacity: 1;
    }
    img, video {
      // transform: scale(1.1);
    }
  }
}

.banner {
  height: 25rem;
  h1,
  p {
    color: white;
  }

  img {
    max-width: 200px;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.6);
    &--transparent{
      background: rgba(45, 55, 67, 0.1);
    }
  }
}

.logo {
  max-width: 200px;
  height: auto;
  background-color: #ffffff;;
}

.border-radius {
  border-radius: var(--card-border-radius);
  &-bottom {
    border-bottom-left-radius: var(--card-border-radius);
    border-bottom-right-radius: var(--card-border-radius);
  }
  &-top {
    border-top-left-radius: var(--card-border-radius);
    border-top-right-radius: var(--card-border-radius);
  }
}

.box-shadow {
  // box-shadow: 0.1em 0.1em 1em rgba(45, 55, 67, 0.1);
  box-shadow: rgba(25, 28, 31, 0.04) 0px 0.5rem 1rem 0px, rgba(25, 28, 31, 0.08) 0px 0rem 1rem 0px, rgba(25, 28, 31, 0.05) 0px 1px 0px 0px inset;
}

.star--shadow {
  text-shadow: 0px 0px 6px #2d3743;
}

/*** MODALS **/
.ant-modal {
  width: max-content;
  max-width: 1000px;
  color: inherit !important;
  transform-origin: 50% !important;
  img {
    max-width: 100%;
  }
  &-title {
    text-transform: uppercase;
    font-weight: bold;
  }
  &-body {
    padding: 0 !important;
  }
  &-header {
    padding: 1.25em 3em 1.25em 2.25em;
  }
  &-close-x {
    i {
      background-image: url("~/assets/images/times.svg");
      background-size: contain;
      background-repeat: no-repeat;
      padding: 0.3em;
      svg {
        opacity: 0;
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    width: calc(100% - 40px) !important;
    top: 5vh;
  }
  @media (max-width: 768px){
    top: 65px;
  }
  @media only screen and (max-width: 1000px) {
    width: calc(100% - 20px) !important;
  }
}

.bg-white.bg-white.bg-white {
  background: #ffffff !important;
}

.bg-light {
  background: var(--ion-color-light) !important;
  &.border-top{
    border-top: 1px solid #ffffff;
  }
  .content-box{
    background: rgba(255,255,255, 0.5);
    box-shadow: 0.1em 0.1em 1em rgba(45, 55, 67, 0.1);
    @media (max-width: 768px){
      background: transparent;
      box-shadow: none;
    }
  }
}
.content-box{
  background: var(--ion-color-light);
  padding: 2em;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0.1em 0.1em 1em rgba(45, 55, 67, 0.2);
  // &.side-scroller{
  //   position: relative;
  //   &:after{
  //     content: "";
  //     background: linear-gradient(to left, rgba(255,255,255, 0.5), transparent);
  //     position: absolute;
  //     top: 0;
  //     width: 5%;
  //     height: 100%;
  //     left: right;
  //   }
  // }
  @media (max-width: 768px){
    background: transparent;
    padding: 15px;
    border-radius: 0;
    box-shadow: none;
  }
  &__no-top{
    margin-top: -1.8em;
  }
}
.description{
  white-space: pre-line;
}


.empty-img{
  width: 80%;
  max-width: 150px;
}

// material buttons colours
.mat-button-base[color="primary"]:not(.mat-flat-button):not(.mat-raised-button):not([disabled]){
  color: var(--ion-color-primary) !important;
}
.mat-flat-button.mat-primary:not([disabled]),
.mat-raised-button.mat-primary:not([disabled]){
  background-color: var(--ion-color-primary) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: var(--ion-color-primary) !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
  background-color: var(--ion-color-primary) !important;
}

.mat-progress-spinner circle, .mat-spinner circle{
  stroke: #ffffff !important;
}

// phone number picker
// was breaking the modals
.cdk-overlay-pane{
  // max-height: 400px;
  max-width: 95% !important;
}

// Mat toggle
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
  line-height: 2.5em !important;
}
.mat-button-base[color="primary"]:not(.mat-flat-button):not(.mat-raised-button):not([disabled]){
  color: var(--ion-color-primary) !important;
}
.mat-flat-button.mat-primary:not([disabled]),
.mat-raised-button.mat-primary:not([disabled]){
  background-color: var(--ion-color-primary) !important;
}

// Mat button toggle
.mat-button-toggle-checked{
  .mat-button-toggle-label-content{
    color: var(--ion-color-primary);
  }
}


// material input
.mat-form-field.mat-focused .mat-form-field-label{
  color: #000000 !important;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #000000 !important;
}

input.mat-input-element{
  color: var(--ion-color-dark);
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #000000 !important;
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background{
  background-color: #000000 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background-color: #000000 !important;
}
.mat-tab-label{
  flex-grow: 1;

}
.mat-dialog-container{
  padding: 2vw !important;
}

.cdk-overlay-pane {
  max-height: 80vh;
}


// TABS
.mat-tab-label {
  &.mat-tab-label-active {
    opacity: 1 !important;
  }
  .mat-tab-label-content {
    font-weight: 900;
    color: #000000 !important;
  }
} 

.mat-tab-body-content {
  overflow-y: hidden;
}

.casestudy-slider {
  .mat-tab-header {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: 1200px) {
      width: 100%;
    }
  }
}

.overlay-wrapper {
  position: relative;
  overflow: hidden;

  .overlay {
    background: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.btn--disabled{
  pointer-events: none;
  filter: grayscale(1);
}

.mobile-hide{
  display: block !important;
  @media (max-width: 768px) {
    display: none !important;
  }
  &.d-flex{
    display: flex !important;
    @media (max-width: 768px) {
      display: none !important;
    }
  }
}
.mobile-show{
  display: none !important;
  @media (max-width: 768px) {
    display: block !important;
  }
  &.d-flex{
    display: none !important;
    @media (max-width: 768px) {
      display: flex !important;
    }
  }
}

.ant-switch-checked{
  background-color: var(--ion-color-primary) !important;
}


.checklist-item
{
  position: relative;
  span{
    display: block;
  }
  &:before{
    content: "";
    display: inline-block;
    width: 4.8px;
    height: 9.6px;
    width: .5rem;
    height: 1rem;
    border-color: var(--ion-color-dark-theme);
    border-style: solid;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 0;
    border-radius: 0;
    background: 0 0;
  }
}

// for second row of pipe html clanedar time
.calendar-time {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: var(--ion-color-medium);
  margin-top: 0.25em;
}

.bg-dark {
  background-color: var(--ion-color-dark-light) !important;
  p, h2, .h2, h3, .h3, a{
    color: var(--ion-color-dark-contrast);
  }
  a:hover {
    color: var(--ion-color-light);
  }
  .border {
    &-left, &-right, &-top, &-bottom, & {
      border-color: var(--ion-color-dark-tint) !important;
    }
  }
}

.bg-dark--animated {
    background: linear-gradient(45deg, #161616 0%, darken(#161616, 50%) 100%);
    background-size: 200% 200%;  
    height: 100%;
    width: 100%;
    animation: backgroundDarkAnimation 15s ease infinite;
}

@keyframes backgroundDarkAnimation { 
  0% {
    background-position: 0% 50%;
  }
  
  50% {
    background-position: 100% 50%;
  }
  
  100% {
    background-position: 0% 50%;
  }
}


.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
  background-color: rgba(var(--ion-color-primary-rgb),0.5) !important;
}
.mat-slide-toggle-thumb {
  background-color: var(--ion-color-primary) !important;
}

.mat-progress-spinner circle, .mat-spinner circle{
  stroke: var(--ion-color-primary) !important;
}
.mat-progress-spinner.mat-secondary circle, .mat-spinner circle{
  stroke: var(--ion-color-primary-contrast) !important;
}

.hide {
  display: none;
}


// modals spacing to the top on mobiles
.cdk-global-overlay-wrapper{
    @media (max-width: 768px) {
      padding-top: 4.5rem;
    }
}

.mat-expansion-panel {
  background: transparent;
  box-shadow: none !important;

  &-header{
    padding: 0 !important;
    font-size: 1rem;
    font-family: "Lato", sans-serif;
  }

  &-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-family: "Lato", sans-serif;
  }
}

.badge-medium {
  background-color: var(--ion-color-light-background);
  color: var(--ion-color-dark-theme);
}

.badge-primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.badge-dark {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}


cdk-virtual-scroll-viewport {
  height: 200vh;
  width: 100%;
}

.cdk-virtual-scroll-content-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  min-width: 0 !important;
  width: 95%;
}


// For place / event pages with altering backgrounds
.page-content--backgrounds {
  section {
    &:nth-of-type(odd) {
      background: var(--ion-color-light) !important;
      &.border-top{
        border-top: 1px solid #ffffff;
      }
      .content-box {
        background: var(--ion-color-primary-contrast);

        @media (max-width: 768px) {
          background: transparent;
        }
      }
    }
  }
}


.equal-icons fa-icon {
  width: 32px;
  height: 32px;
}


// MAPBOX POPUPS

.map-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 2;
  position: fixed;
  top: 0;
  opacity: 1;
  left: 0;
  transition: opacity 400ms ease-in-out;

  @media (max-width: 768px) {
    height: 100vh;
  }
}

// FOR MAP TOUTS AND POPUPS

.mapboxgl-popup{
  pointer-events: all !important; // to allow popup click
}
// .mapbox-popup, .mapboxgl-popup-content {
//   pointer-events: all; // to allow popup click
//   // pointer-events: none; // to allow popup click
// }

.mapboxgl-popup-content{
  padding: 0 !important;
}

.mapbox-popup {
  cursor: pointer;
  position: relative;
  font-size: 16px;
  min-width: 250px;

  &:hover {
    background-color: var(--ion-color-light);
  }

  .mapbox-logo {
    border-radius: 100%;
    overflow: hidden;
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: var(--ion-color-dark-contrast);
    border: 2px solid var(--ion-color-dark-contrast);
    left: 0;
    top: 0;
    transform: translateY(-50%);
    position: absolute;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .image {
    width: 100%;
    overflow: hidden;
    height: 100%;
    border-radius: 0;
    background-size: cover;
    background-position: center;
  }
}


// for any locked out content for downloads
.locked-blurred {
  filter: blur(4px);
  opacity: 0.8;
  pointer-events: none;
}



// google map

.border-radius .map-container {
  border-radius: var(--card-border-radius);
}

.one-line {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.section-banner {
  min-height: 507px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;

  h1 {
    font-size: 56px;
  }

  @media (max-width: 768px) {
    min-height: 300px;
    font-size: 18px;

    h1 {
      font-size: 40px;
    }
    
  }
}

.heading .heading-desc {
  font-size: 22px;
  line-height: 1.44;
  font-weight: 100;
  margin-top: 25px;
}
.heading .heading-desc.size-l {
  font-size: 24px;
}
.heading .heading-desc a {
  color: var(--sala-primary-navy, #000000);
}
.heading .heading-desc a:hover {
  text-decoration: underline;
}
.heading .heading-desc p {
  margin-bottom: 18px;
}
.heading .heading-desc p:last-child {
  margin-bottom: 0;
}
.heading .heading-desc.w600 {
  font-weight: 600;
}



.bg-instagram {
	/* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
	/* height: 100vh; */
  
  background: #c13584;
  // background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  /* background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ); */

  // background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  // background: radial-gradient(circle at 30% 107%, #FFE9F0 0%, #FAECDB 5%, #D6EBE4 45%,#FFE9F0 60%,#FAECDB 90%);
  background: linear-gradient(90deg, #FFE9F0 0%, #FAECDB 51.04%, #D6EBE4 100%);
  background-size: 100%;
  // background-size: 400% 400%;
}

.bg-instagram--animate {
	// animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.swiper-button-next, .swiper-button-prev {
  color: var(--ion-color-primary) !important;
  width: 20px;
  position: absolute;
  height: 50px;
  top: 50%;
  z-index: 1;

  svg {
    width: 20px;
    height: 33px;
  }
}
.swiper-button-next {
  right: 0;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev, 
.swiper-container-rtl .swiper-button-next{
    background-image: none;
}
.swiper-button-next,
.swiper-button-prev, 
.swiper-container-rtl .swiper-button-prev,
.swiper-container-rtl .swiper-button-next{
  fill: var(--ion-color-primary) !important;
}
slides:not(.slides--dots-bottom) {
  .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 90px;
}
}

.swiper-wrapper {
  // transition-timing-function: ease-in-out;
}
.swiper-pagination-bullets{
  display: flex;
  width: 100%;
  justify-content: center;
  bottom: 0 !important;
  // opacity: 0.7;
  padding: 0.5em;
  margin-top: 4em;
}

.swiper-pagination-bullet{
  // box-shadow: 0px 0px 1px #000000;
  // flex-grow: 1;
  width: 15px;
  height: 15px;
  margin: 0px 5px;
  border-radius: var(--card-border-radius);
  // border: 1px solid ;
  background: #000000;
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
  vertical-align: middle;
  &:first-child:last-child { // Hides dot if only one image present (ts wasn't working)
    display: none;
  }
  &-active{
    // background: #ffffff;
    opacity: 1;
    width: 50px;
    // width: 10px;
    // height: 10px;
  }
}

@media (prefers-color-scheme: dark) {
  .slider--dark-dots {
    .swiper-pagination-bullet {
      background: #000000;
    }
  }
}


.spinner-primary circle {
  stroke: var(--ion-color-primary) !important;
 }