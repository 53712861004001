.btn {
  background-color: var(--ion-color-primary, #000000);
  border-color: var(--ion-color-primary, #000000);
  color: var(--ion-color-primary-contrast, #ffffff);
  height: 59px;
  border: 1px solid transparent;
  display: inline-flex;
  padding: 14px 26px;
  font-size: 22px;
  line-height: 1.5;
  border-radius: 3rem;
  transition: all .3s;
  font-weight: 400;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: inherit;
  }

  &--border {
    background-color: transparent;
    color: var(--ion-color-primary);
    border: 1px solid var(--ion-color-primary);

    &:hover {
      background-color: var(--ion-color-light);
    }
  }

  &--light {
    background: var(--ion-color-light);
    color: var(--ion-color-dark);
    &:hover {
      color: var(--ion-color-dark-theme-shade);
    }
  }
  &--border-light {
    background: transparent;
    color: var(--ion-color-light);
    border: 1px solid var(--ion-color-light);
    font-size: 1.1rem;
    border-radius: 10px;
    &:hover {
      color: var(--ion-color-light-shade);
    }
  }
}

.link {
  font-weight: 700;
  color: var(--ion-color-dark-theme);
  & > fa-icon{
    color: var(--ion-color-dark-theme);
    transform: translate3d(0,0,0);
    transition: transform 0.2s ease-in-out;
  }
  &:hover,
  &:active {
    text-decoration: none;
    color: var(--ion-color-dark-theme);
    & > fa-icon{
      transform: translate3d(50%, 0, 0);
    }
  }
  &-underline {
    text-decoration: underline !important;
  }
}

.btn.btn-primary {
  // background: -webkit-linear-gradient(
  //   left,
  //   var(--ion-color-primary) 0%,
  //   var(--ion-color-primary-tint) 100%
  // );
  // background: linear-gradient(
  //   to right,
  //   var(--ion-color-primary) 0%,
  //   var(--ion-color-primary-tint) 100%
  // );
  color: #ffffff;
  background: var(--ion-color-dark-theme);
  text-transform: uppercase;
    padding: 1em 2em;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    max-width: 250px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    line-height: normal;
    z-index: 1;
    transition: all 0.3s ease-out;
    border-radius: .25em;
    height: auto;
    font-weight: 700;
    &:before {
      content: '';
      width: 300px;
      height: 300px;
      border-radius: 100%;
      background: var(--ion-color-dark-theme-tint);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.5s ease-out;
      z-index: -1;
    }
    &:hover {
      &:before {
        transform: translate(-50%, -50%) scale(1);
      }
    }

}

.bg-dark {
  .btn.btn-primary {
    color: var(--ion-color-dark) !important;
    background: -webkit-linear-gradient(
      left,
      var(--ion-color-dark-contrast) 0%,
      var(--ion-color-light) 100%
    );
    background: linear-gradient(
      to right,
      var(--ion-color-dark-contrast) 0%,
      var(--ion-color-light) 100%
    );
    &:before {
      background: var(--ion-color-light-shade);
    }
  }
}


.btn-outline-primary {
  background: #ffffff;
  color: var(--ion-color-primary);
  border: 3px solid var(--ion-color-primary);
  &:hover {
    background-color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }
}

a{
  text-decoration: none !important;
}

.mat-flat-button.mat-primary:not([disabled]), .mat-raised-button.mat-primary:not([disabled]){
  background-color: var(--ion-color-dark-theme) !important;
}


.btn--map {
  position: fixed;
  z-index: 3;
  padding: 1rem 1.5rem;
  height: 65px;
  font-size: 1.25rem;
  text-transform: uppercase;

  right: 5%;
  left: 5%;
  max-width: 12em;
  bottom: 0;
  margin: 0 auto;
  border-radius: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:hover {
    color: var(--ion-color-light);
  }

  &--close {
    right: 2%;
    left: auto;
    top: 90px;
    font-size: 1.75rem;
    border-radius: 5px;
    height: 3rem;
  }

  @media(max-width: 768px) {
    padding: 1.25rem;
    height: 50px;
    font-size: 1rem;

    &--close {
      width: 2em;
      height: 2em;
      font-size: 1.5rem;
    }
  }

}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  min-width: unset !important;
  &.mat-button-thin {
    span {
      font-weight: 100 !important;
    }
    &.font-weight-bold {
      span {
        font-weight: 400 !important;
      }
    }
  }
}




.btn-light {
  background: #ffffff;
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
  &:hover {
    background: #f3f3f3;
  }
}


.socialbtn {
  color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 0.5em;
  padding-top: 0.15em;
  padding-bottom: 0.15em;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  span {
    font-weight: normal;
    span {
      font-weight: 100;
    }
  }
  &:hover {
    fa-icon {
      color: #ffffff;
      background: #000000;
    }
  }
  a {
    color: #ffffff;
    &:hover {
      text-decoration: none;
      color: #ffffff;
    }
  }
  fa-icon {
    background-color: #ffffff;
    border-radius: 100%;
    padding: 0.3em;
    border: 2px solid #000000;
    transition: all 0.15s ease-out;
    margin-right: 0.15em;
    svg {
      width: 1.2em;
      height: 1.1em;
    }
  }
  &--instagram {
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);;
    fa-icon {
      border: 2px solid var(--ion-color-instagram);
      color: var(--ion-color-instagram);
    }
    &:hover {
      fa-icon {
        color: #ffffff;
        background: var(--ion-color-instagram);
      }
    }
  }
  &--tiktok {
    background-color: #000000;
    fa-icon {
      color: #000000;
    }
  }
}

.button {
  &--outline {
    border-color: #000000 !important;
  }
}



.ping-button {
  padding: 0.35rem 1.5rem;
  border: 1px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
  border-radius: 5px;
  background-color: #ffffff;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
  display: inline-block;

  @media(max-width: 479px) {
    padding: 0.5rem 1.2rem !important;
  }
}