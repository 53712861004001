
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ping-web-primary: mat-palette($mat-indigo);
$ping-web-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ping-web-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$ping-web-theme: mat-light-theme($ping-web-primary, $ping-web-accent, $ping-web-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ping-web-theme);

/* You can add global styles to this file, and also import other style files */
@import "assets/scss/variables.scss";
@import "assets/scss/app.scss";
@import "assets/scss/typography.scss";
@import "assets/scss/animations.scss";
@import "assets/scss/buttons.scss";


html, body { height: 100%; }
body { margin: 0; font-family: 'Lato', "Helvetica Neue", sans-serif;}

.shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #e9e9e9 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
  }

  @media (prefers-color-scheme: dark) {


    .shimmerBG {
      background: var(--ion-color-light);
      background: linear-gradient(to right, var(--ion-color-light-shade) 8%, var(--ion-color-light-tint) 18%, var(--ion-color-light-shade) 33%);
    }
  }


@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
  }


.la-ball-atom > div {
  &:nth-child(1) {
    width: 80px !important;
    height: 80px !important;
    background: var(--ion-color-dark) !important;
    background-size: contain !important;
  }
  &:before {
    background: var(--ion-color-primary) !important;
  }
}


// FONT AWESOME CONTENT LAYOUT SHIFT
fa-icon svg {
  display: inline-block;
  font-size: inherit;
  font-size: 1em;
}
fa-icon .fa-2x{
  font-size: 2em;
}
fa-icon .fa-3x{
  font-size: 3em;
}
fa-icon .fa-4x{
  font-size: 4em;
}
fa-icon .fa-5x{
  font-size: 5em;
}
